// custom variables
$background-color: #f7f6fa;
$border-grey: #c8c8c8;
$border-colour: #ececec;
$hover-colour: #f5f8fb;
$box-grey: #979797;
$blue: #49a3bc;
$light-blue: #5bcfbf;
$black: #242021;
$white: #ffffff;
$light-green: #a0cd4e;
$lighter-green: #ade5c8;
$darker-green: #167c2e;
$dark-green: #24c143;
$pale-green: #e7f2d2;
$paler-green: #c5fdc1;
$main-colour: #43a0b1;
$main-offset: #3390a1;
$main-colour-hover: #56cbe0;
$orange: #f7a500;
$red: #f24a21;
$green: #21ba45;
$purple: #bb64ff;
$scrollbar-grey: #707070;
$darker-blue: #3562b3;
$less-pale-blue: #bde9ff;
$pale-blue: #e2f1f4;
$paler-blue: #f7f6fa;
$red-tint: #fdecea;
$orange-tint: #fffbf2;
$green-tint: #e9f6e6;
$darker-green-tint: #d1f3c9;
$grey-tint: #eeeeee;
$purple-tint: #ffd8ff;
$divider-grey: #e0e0e0;
$box-shadow-black: #00000016;
$faded-blue: #7282b2;
$faded-green: #72b287;
$faded-green-tint: #f5faf7;
$faded-blue-tint: #f5f6fa;
$faded-red-tint: #faf4f2;
$residential-green: #61bd4f;
$commercial-blue: #6485ff;
$light-grey: #c3c3c3;
$assigned-grey: #8796a1;
$selected-grey: #d6d6d6;
$blackish: #333647;
$red-text: #ff6264;
$subtext-blue: #31acac;
$yellow-tint: #fffff0;

$picker-selected-bg-color: #4ecdcd;
$faded-picker-bg-color: #f5f6fa;
$picker-bg-color: #e2f1f4;

$table-header-bg-color: #f4f5f9;
$table-item-border-color: #d8def0;

$cell-hover: #f1eff6;

// font weightings
$light-weight: 300;
$regular-weight: 400;
$semibold-weight: 600;
$bold-weight: 700;
$extrabold-weight: 800;

// other font names
$Lato: "Lato";
$Calibri:
  Calibri,
  Candara,
  Segoe,
  Segoe UI,
  Optima,
  Arial,
  sans-serif;

// breakpoints
$desktop: 1068px;
$tablet: 640px;

$navHeight: 64px;
$page-content-header-height: 48px;

// cinnamon overrides
$import-google-fonts: true !default;
$font-url: "https://fonts.googleapis.com/css?family=Open+Sans" !default;
$font-name: "Open Sans" !default;
$font-family: $font-name, Arial, Helvetica, sans-serif !default;
$font-size: 14px !default;
$text-color: #393939;
$link-color: #a0cd4e;
$button-background-color: $main-colour;
$button-text-color: $white;
$toggle-background-color: $main-colour;
$positive-color: $dark-green;
$negative-color: $red;
