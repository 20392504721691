@use "sass:math";
@use "./variables.scss" as *;
@use "./mixins.scss" as *;

.main-nav {
  width: 100%;
  background-color: $black;
  color: $white;
  height: $navHeight;

  .ui.menu {
    color: $white;
    display: flex;
    height: 100%;
    align-items: center;
    .cin.nav-menu-item {
      height: 100%;
      color: $white;
      @include Calibri14RegularText;
      &.active {
        @include Calibri14BoldText;
        > .nav-underline {
          position: relative;
          display: inline-block;
          &::after {
            $activeOffset: 0.5rem;
            content: "";
            display: inline-block;
            width: calc(100% + #{$activeOffset});
            background-color: $light-green;
            height: 2px;
            position: absolute;
            bottom: -0.5rem;
            left: #{math.div($activeOffset, 2) * -1};
          }
        }
      }
    }
  }

  .main-logo {
    margin-left: 3rem;
    .ui.image {
      max-height: calc(#{$navHeight} * 0.7);
    }
  }

  .account-item {
    margin-right: 3rem;
  }
}

.nav-popup-links {
  display: flex;
  flex-wrap: wrap;
  min-width: 200px;
  & > * {
    width: 50%;
    padding: 0.5rem;
  }
}

.admin-popup.ui.flowing.popup {
  max-width: 350px;
}

.avatar-circle {
  @include Avatar(40px);
}
