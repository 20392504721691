@use "./variables.scss" as *;
@use "./mixins.scss" as *;

.contract-form {
  .ui.button {
    position: absolute;
    bottom: 0;
  }

  .ui.label {
    background-color: $picker-selected-bg-color;
    color: $white;
  }

  .column {
    .inline.field {
      label {
        font-weight: normal;
      }
    }

    .ui[class*="right labeled"].icon.button {
      padding-left: 0 !important;
    }

    .contract-pricing-btn {
      background: $white;
      color: $text-color;

      .icon {
        background: none !important;
      }
    }
  }

  .two.row.column.contract-contact-row {
    padding-bottom: 0;
  }

  .two.row.column.contract-contact-detail-row {
    padding-bottom: 0;
    padding-top: 0;
  }

  .two.row.column.contract-contact-detail-row.first {
    padding-top: 1em;
  }

  .column.total-value {
    label {
      font-size: 13px;
      font-weight: bold;
    }
  }

  .row.contract-pricing-header {
    .column.job-type {
      width: 20%;
    }

    .column.column.number-of-plots {
      width: 15%;
    }

    .column.invoicing {
      width: 55%;
      display: flex;
      justify-content: center;
      margin-right: 1rem;
    }

    label {
      font-size: 13px;
      font-weight: bold;
    }
  }

  .row.contract-pricing {
    .column.value {
      width: 20%;
      .field {
        .labeled.input {
          width: 70% !important;
        }
      }
    }

    .column.number-of-plots {
      width: 15%;
      align-self: flex-end;
      .inline.required.field {
        label {
          margin-right: 0;
        }
      }
    }

    .column.invoicing-radio-group {
      width: 55%;
      align-self: flex-end;
      .field {
        display: flex;
        flex-wrap: wrap;

        label {
          width: 100%;
        }

        .ui.radio.checkbox {
          margin: 0.5em 2em 0.5em 0;
        }
      }
      .cin.form-error {
        margin-top: 0.5em;
      }
    }

    .column.clear-btn {
      padding: 0;
      .ui.blue.basic.button {
        box-shadow: 0 0 !important;
      }
    }
  }

  .clear-contract-pricing {
    @include DefineFont(12px);
  }

  .row.extra-pricing {
    padding-top: 0;

    .column.price-per-plot {
      width: 20%;
      margin-right: 2em;
      .field {
        .labeled.input {
          width: 70% !important;
        }
      }
    }

    .column.percentage-of-price {
      width: 15%;
    }
  }
}

.contracts-table.ui.table {
  border-collapse: separate;
  border-spacing: 0 0.25rem;
  $border-width: 3px;
  $line-colours:
    "fencing" $red-text,
    "landscaping" $orange,
    "landscapingandfencing" $purple,
    "groundsmaintenance" $green,
    "burials" $darker-green;
  $tint-colours:
    "green" $green-tint,
    "orange" $orange-tint,
    "red" $red-tint,
    "grey" $grey-tint,
    "light-blue" $pale-blue,
    "yellow" $yellow-tint;

  tbody {
    tr {
      @each $name, $colour in $line-colours {
        &.highlight-#{$name} {
          td:first-child {
            border-left: solid #{$border-width} $colour !important;
          }
        }
      }

      @each $name, $colour in $tint-colours {
        &.tint-#{$name} {
          background-color: $colour;
        }
      }
    }
  }
}

.contract-header-button.ui.button {
  height: 32px;
  padding: 0 0.5rem;
  margin-right: 1rem;
}

.line-panel.ui.segment.contract-card {
  $line-colours:
    "orange" $orange,
    "red" $red,
    "purple" $purple,
    "green" $green,
    "dark-green" $darker-green,
    "blue" $blue;
  $border-width: 3px;

  @each $name, $colour in $line-colours {
    &.highlight-#{$name} {
      &:before {
        content: none;
      }

      border-left: solid #{$border-width} $colour;
      border-top: 0;
      border-right: 0;
      border-bottom: 0;
    }
  }

  margin-bottom: 1rem;
}

.contract-panel {
  flex-grow: 1;
  margin-left: 0.5rem;
  width: 45%;

  .ui.compact.button {
    height: initial;
  }

  .light-panel.ui.segment {
    height: 60vh;

    > header {
      height: 3.5rem;

      .action-bar {
        height: 100%;
        align-items: center;
      }
    }

    & > div {
      padding-bottom: 1rem;
    }
  }

  .contract-details {
    display: flex;
    flex-direction: column;

    .light-panel-content {
      overflow: auto;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .ui.segment {
        overflow: auto;

        .ui.form {
          height: 100%;

          .field {
            height: 100%;
          }
        }
      }
    }
  }

  .ui.compact.button.cancel-button {
    background: none;
    color: $black;
  }

  .contract-contact-display {
    display: flex;

    span {
      align-self: center;
      margin-right: 1em;
      white-space: nowrap;
    }

    .ui.compact.dropdown.cin.action-menu.contract-action-menu {
      display: flex;
      width: 100%;
    }
  }

  .invoicing-options {
    padding: 0.5rem;
    .ui.compact.button.invoicing-button {
      background: none;
      color: $black;
      padding-left: 0 !important;
      padding-right: 2.5em !important;
      margin-bottom: 1em;

      .caret.right.icon,
      .caret.down.icon {
        background: none;
      }
    }

    .cin.data-list-item {
      padding: 0 0 1rem 0;
    }
  }
}

.contract-panel:first-child {
  margin-left: 0;
  width: 55%;
}

.ui.buttons.header-button-group {
  .ui.button {
    align-self: center;
    background: none;
    margin-right: 0;
    padding-top: 0;
    color: $black;
    @include Calibri18BoldText;
    border-bottom: 3px solid $white;
  }

  .ui.button.selected {
    border-bottom: 3px solid $blue;
  }
}

.list-filters {
  .ui.selection.dropdown.type {
    width: 15.5em !important;
  }
}

.contract-pricing-item + .contract-pricing-item {
  margin-top: 0.5em;
}

.plots-table.ui.table {
  tbody {
    tr {
      &.tint-green {
        background-color: $green-tint;
      }

      .ui.disabled.checkbox {
        opacity: 40%;
      }
    }
  }
}
