@use "./variables.scss" as *;
@use "./mixins.scss" as *;

.clear-label,
.quick-date-select {
  cursor: pointer;
  color: $main-colour;
  &:hover {
    color: $main-colour;
  }
}
