@use "./variables.scss" as *;
@use "./mixins.scss" as *;

.invoices-table.ui.table {
  $border-width: 3px;

  border-collapse: separate;
  border-spacing: 0 0.25rem;

  $line-colours:
    "blue" $main-colour,
    "green" $dark-green,
    "orange" $orange,
    "residential" $residential-green,
    "commercial" $commercial-blue,
    "fencing" $red,
    "landscaping" $orange,
    "landscapingandfencing" $purple,
    "red" $red,
    "grey" $box-grey;

  th {
    padding: 0.92857143em 0.78571429em;

    &:first-child {
      padding-left: 2rem;
    }
  }

  tbody {
    tr {
      td.job-link {
        a {
          cursor: pointer;
          color: $blue;
          text-decoration: underline;
          font-weight: $bold-weight;
          display: block;

          &:hover {
            color: $main-colour-hover;
          }
        }
      }

      > td:last-child {
        padding: 0;
      }

      @each $name, $colour in $line-colours {
        &.highlight-#{$name} {
          > td:first-child {
            border-left: solid #{$border-width} $colour !important;
          }
          &.expanded-row {
            td {
              border: none !important;
            }
          }
        }
      }
    }
  }

  .ui.table.invoice-lines-table {
    border: 1px solid $border-colour;
    border-collapse: collapse;
    th,
    td {
      border: 1px solid $border-colour !important;
      &:first-child {
        padding-left: 0.78571429em;
      }
    }
  }
}

.invoice-header {
  padding-left: 2rem;
  padding-right: 5rem;
  .customer-reference {
    @include OpenSans16BoldText;
    display: grid;
    align-items: center;
    margin-right: 4rem;
  }

  .department {
    width: 20%;
    margin-right: 2.5rem;
  }

  .order-number {
    width: 20%;
    margin-right: 2.5rem;
  }

  .same-width {
    margin-right: 2.5rem;
  }

  .invoice-type {
    width: 12%;
    margin-right: 2.5rem;
  }

  .due-date {
    width: 10%;
    margin-right: 2.5rem;
  }
}

.invoice-line-list {
  margin-top: 2rem;
  margin-bottom: 1rem;

  .fields:first-child {
    .field label {
      visibility: visible;
    }
  }

  .fields {
    margin: 0 !important;
    .field label {
      visibility: collapse;
    }
    .read-only {
      width: 5em;
      display: flex;
      align-items: center;
      height: 32px;
    }

    .ui.button.remove-button {
      align-self: flex-start;
      padding: 7px 7px;
      margin: 1.5em 0.5em 0.5em 0.5em;
      i {
        height: 15px;
      }
    }

    .form-error {
      max-width: fit-content;
    }

    .description {
      flex: 1 1 50%;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .job-field {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      min-width: 92px;
      .read-only {
        width: 100%;
      }
    }

    textarea {
      padding: 0.5em 1rem;
    }
  }

  button.ui.button {
    margin: 0.5em;
  }
}

.invoice-total {
  &.full-width {
    width: 100%;
    margin-top: 2rem !important;
  }
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  .net-price {
    margin-left: 3rem;
    width: 120px;
  }

  .vat {
    width: 245px;
  }

  .total {
    width: 115px;
  }
}
