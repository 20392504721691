@use "./variables.scss" as *;
@use "./mixins.scss" as *;

.address-tile.ui.segment {
  border-radius: 0;
  border: 0;
  padding-top: 13px;
  padding-bottom: 13px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: 75px;
  background-position: right center !important;
  padding: 0.5rem 75px 0.5rem 0.5rem;

  > header {
    white-space: pre-wrap;
    @include OpenSans14RegularText;
  }

  > span {
    @include OpenSans12RegularText;
    position: relative;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 6px 0;

    > p {
      display: inline-block;
      vertical-align: middle;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  > img {
    position: absolute;
    right: 1rem;
  }

  img.primary {
    display: inline;
    margin-right: 5px;
  }
}

.postcode-search {
  display: flex;
  .ui.dropdown {
    min-height: 32px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;

    .text {
      align-self: center;
    }
  }

  .ui.message {
    padding-top: 0;
    height: 100%;
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }

  .search-field {
    margin-right: calc(36px + 2rem);
    flex-shrink: 0;
  }
  .result-fields {
    flex-grow: 1;
  }
}
