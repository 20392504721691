@use "./variables.scss" as *;
@use "./mixins.scss" as *;

.ui.segment.system-settings {
  .action-bar.header-bar {
    margin-bottom: 1.5rem;
    .header-bar-title {
      @include Calibri20BoldText;
    }
  }
  .ui.header {
    @include Calibri18BoldText;
  }
  .settings-section {
    margin: 0.5rem 0 2rem 0;
  }
  .ui.form.system-calendar-form {
    .fields {
      > div {
        margin-right: 3rem;
      }
      > div:last-child {
        margin-right: 0;
      }
    }
    .calendar-url {
      flex-grow: 1;
      label {
        display: flex;
        img {
          vertical-align: middle;
          padding: 0 4px 0 4px;
        }
      }
    }
  }
}
