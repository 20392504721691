@use "./variables.scss" as *;
@use "./mixins.scss" as *;

.swimlanes {
  $defaultLaneWidth: 400px;
  margin-top: 16px;
  overflow-x: auto;
  min-height: 50vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .swimlanes-headers {
    display: flex;
    padding: 0;
    margin-bottom: 0;
    height: 54px;
    & > div {
      flex-shrink: 0;
      flex-direction: column;
      justify-content: center;
      @include OpenSans13RegularText;
      min-width: $defaultLaneWidth;
      display: flex;
      span {
        padding: 1rem 0.5rem;
      }
    }
  }
  .lanes-wrapper {
    display: flex;
    padding: 0.5rem 0;

    & > div {
      flex-shrink: 0;
      min-width: $defaultLaneWidth;

      .load-more {
        text-align: center;
      }
    }

    & > div:first-child {
      .contract-card.ui.segment,
      .enquiry-card.ui.segment {
        margin-left: 0;
      }
    }

    & > div:last-child {
      .contract-card.ui.segment,
      .enquiry-card.ui.segment {
        margin-right: 0;
      }
    }
  }

  .contract-card.ui.segment,
  .enquiry-card.ui.segment {
    margin: 0.5rem;
  }
}
