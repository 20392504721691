@use "./variables.scss" as *;
@use "./mixins.scss" as *;

.split-page {
  display: flex;
  min-height: 100vh;
  > .left-content {
    width: 40%;
    min-height: 100%;
  }
  > .right-content {
    flex-grow: 1;
    min-height: 100%;
  }
}

.page-content {
  .page-content-header {
    height: $page-content-header-height;
    min-height: $page-content-header-height;
    background-color: $pale-green;
    padding: 0 4rem;
    display: flex;
    align-items: center;
    @include OpenSans16BoldText;
  }
  .page-content-wrapper {
    padding: 1rem 4rem;
  }
}

.header-bar.cin.action-bar {
  flex-wrap: wrap;
  margin-bottom: 0;
  .header-bar-title {
    align-self: center;
    margin-right: 1rem;
    @include Calibri18BoldText;
    color: $black;
  }
  &.cin.action-bar {
    margin-bottom: 0;
  }
  .right-content {
    display: inline-flex;
    height: 100%;
    align-items: center;
  }
}

.line-panel.ui.segment.sub-header {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.line-panel.ui.segment {
  $border-width: 3px;
  margin: 0;
  position: relative;
  $line-colours:
    "blue" $main-colour,
    "green" $dark-green,
    "orange" $orange,
    "red" $red,
    "grey" $box-grey,
    "faded-blue" $faded-blue,
    "faded-green" $faded-green,
    "residential-green" $residential-green,
    "commercial-blue" $commercial-blue,
    "contract-red" $red,
    "burials-black" $black;
  $tint-colours:
    "green" $green-tint,
    "orange" $orange-tint,
    "red" $red-tint,
    "blue" $paler-blue,
    "grey" $grey-tint,
    "faded-blue" $faded-blue-tint,
    "faded-green" $faded-green-tint,
    "faded-red" $faded-red-tint;
  @each $name, $colour in $line-colours {
    &.highlight-#{$name} {
      border: solid #{$border-width} transparent;
      &:before {
        content: "";
        position: absolute;
        top: -$border-width;
        left: -$border-width;
        height: calc(100% + #{$border-width * 2});
        width: $border-width;
        background-color: $colour;
      }
    }
    &.highlight-full-#{$name} {
      border: solid $border-width $colour;
    }
  }
  @each $name, $colour in $tint-colours {
    &.tint-#{$name} {
      background-color: $colour;
    }
  }
  &.has-background {
    background-repeat: no-repeat;
    background-position: right bottom;
  }
  & > .cin.loadable {
    width: 100%;
  }

  &.dashed-border {
    border-style: dashed;
    border-width: 2px;
  }
}

.line-panel.ui.segment.header {
  min-height: 64px;
  padding: 0 1em;
  display: flex;
  align-items: center;

  .header-bar {
    flex-grow: 1;

    .ui.icon {
      display: inline-flex;
    }
  }
}

.banner-panel.ui.segment {
  display: flex;
  justify-content: space-between;
  margin: 0em !important;
  $line-colours:
    "blue" $main-colour,
    "green" $dark-green,
    "orange" $orange,
    "red" $red;
  @each $name, $colour in $line-colours {
    &.highlight-#{$name} {
      border-left: solid 3px $colour;
    }
  }
  & > header {
    min-height: 60px;
    align-self: center;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    flex-grow: 1;

    & > p {
      min-height: 28px;
      display: flex;
      align-items: flex-start;
      margin-top: 1rem;
      margin-bottom: 0rem;

      & > a {
        @include OpenSans14RegularText;
      }

      & > span {
        margin-right: 1.5rem;
        & > span {
          white-space: pre-wrap;
        }
      }
    }

    & > .banner-panel-additional-info {
      & > p {
        margin-top: 0.5rem;
        margin-bottom: 0.25rem;
        & > span {
          margin-right: 1.5rem;
        }
      }
    }

    & > .banner-panel-footer {
      & > p {
        margin-top: 0.5rem;
        & > span {
          margin-right: 1.5rem;
        }
      }
    }

    .banner-icon {
      display: inline !important;
      margin-right: 0.5rem;
    }

    @include Lato16RegularText;
    .title {
      @include Lato16BoldText;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
  & > footer {
    align-self: center;
    & > .form {
      & > .field {
        min-width: 150px !important;
      }
    }
  }
  a.customer-link {
    @include Lato16BoldText;
  }
}

.ui.segment.content-panel {
  position: relative;
  &[class*="highlight"] {
    padding-left: calc(1em + 8px);
  }
  &[class*="highlight"]::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 8px;
    background-repeat: repeat-y;
    background-size: 8px 32px;
  }
  &.highlight-red::before {
    background-image: url("~assets/red-highlight.svg");
    background-size: 8px 64px;
  }
  &.highlight-orange::before {
    background-image: url("~assets/orange-highlight.svg");
  }
  &.highlight-green::before {
    background-image: url("~assets/green-highlight.svg");
  }
  &.highlight-light-blue::before {
    background-image: url("~assets/light-blue-highlight.svg");
  }
  &.highlight-blue::before {
    background-image: url("~assets/blue-highlight.svg");
  }
  &.tint-red {
    background-color: $red-tint;
  }
  &.tint-orange {
    background-color: $orange-tint;
  }
  &.tint-green {
    background-color: $green-tint;
  }
  &.tint-light-blue {
    background-color: $paler-blue;
  }
  &.tint-blue {
    background-color: $pale-blue;
  }
  &.highlight-red.selected {
    outline: solid 1px $red;
  }
  &.highlight-orange.selected {
    outline: solid 1px $orange;
  }
  &.highlight-green.selected {
    outline: solid 1px $dark-green;
  }
  &.highlight-blue.selected {
    outline: solid 1px $blue;
  }
  .tight {
    padding: 0.4rem 1rem 0.4rem 2rem;
    margin: 0 0 0.5rem 0;
  }
  &.overview-comments {
    max-height: 275px;
  }
}

.tabbed-container {
  position: relative;

  .cin.tab-menu-item {
    @include Calibri14RegularText;
    line-height: 24px;
    position: relative;
    border-width: 3px !important;
  }

  .active.item {
    @include Calibri14BoldText;
    border-color: $main-colour !important;
  }

  .tabbed-content {
    margin-top: 1.5rem;
    &.hidden {
      display: none;
    }
  }
}

.light-panel.ui.segment {
  padding: 0;
  & > header {
    @include OpenSans16BoldText;
    padding: 1rem;
    border-bottom: 1px solid $divider-grey;

    .cin.action-bar {
      margin-bottom: 0;
    }
  }

  & > div {
    padding: 1rem;
  }
  pre {
    @include OpenSans14RegularText;
  }
}

.scrollbar-wrapper {
  overflow-y: hidden;
  position: absolute;
  height: 100%;
  width: 100%;

  .scrollbar {
    height: 100%;
    .simplebar-scrollbar.simplebar-visible:before {
      background-color: $scrollbar-grey;
      opacity: 1;
    }
  }
}

.scrollable-list-panel {
  display: flex;
  flex-direction: column;
  min-height: 250px;
  height: 50vh;
  & > .light-panel-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }

  .cin.loadable.fit-loader {
    height: 100%;
  }
}
.scrollable-list-panel-list {
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  & > .cin.loadable.fit-loader {
    display: flex;
    flex-direction: column;
  }
  .scrollable-list-panel-add {
    max-height: 90px;

    .field {
      margin-bottom: 0;
      flex-grow: 1;
      margin-left: 0.25rem;
      textarea {
        border-radius: 0;
      }
    }
    .ui.button {
      border-radius: 0;
      margin: 0 0.25rem;
      padding: 0.5rem;
      .icon {
        margin: 0 !important;
      }
    }
  }
  .form {
    display: flex;
  }

  .scrollable-list-panel-timeline {
    flex-grow: 1;
    position: relative;
    padding: 0.25rem;
  }
}

.ui.segment.expandable-content-panel {
  header {
    display: flex;
    justify-content: space-between;
    @include OpenSans16BoldText;
    cursor: pointer;
  }
}

.filter-bar.cin.action-bar {
  flex-wrap: wrap;
  margin-bottom: 0;
  .filter-bar-title {
    align-self: center;
    margin-right: 1rem;
    @include Calibri18BoldText;
    color: $black;
  }
  &.cin.action-bar {
    margin-bottom: 0;
  }
  .search-filter-display {
    .field {
      margin: 0;
    }
  }
  .right-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
