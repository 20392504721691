@use "./variables.scss" as *;
@use "./mixins.scss" as *;

.quotes-table.ui.table {
  border-collapse: separate;
  border-spacing: 0 0.25rem;

  $border-width: 3px;
  $line-colours:
    "residential-green" $residential-green,
    "commercial-blue" $commercial-blue;
  $tint-colours:
    "green" $green-tint,
    "orange" $orange-tint,
    "red" $red-tint,
    "grey" $grey-tint,
    "light-blue" $pale-blue;

  th {
    padding: 0.92857143em 0.78571429em !important;

    &:first-child {
      padding-left: 2rem !important;
    }
  }

  tbody {
    .document-download {
      width: 5%;
    }

    td.current-quote {
      color: $blue;
      text-decoration: underline;
      font-weight: $bold-weight;
      cursor: pointer;

      &:hover {
        color: $main-colour-hover;
      }
    }

    tr {
      @each $name, $colour in $line-colours {
        &.highlight-#{$name} {
          td:first-child {
            border-left: solid #{$border-width} $colour !important;
          }
        }
      }

      @each $name, $colour in $tint-colours {
        &.tint-#{$name} {
          background-color: $colour;
        }
      }
    }
  }
}
