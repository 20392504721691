@use "./variables.scss" as *;
@use "./mixins.scss" as *;

.green-gradient-btn.ui.button {
  background: linear-gradient(
    100deg,
    $light-green 0%,
    $dark-green 100%
  ) !important;
  color: $black;
  @include Lato16RegularText;
}

.ui.button {
  @include OpenSans14RegularText;
  border-radius: 3px;
  &:hover,
  &:active,
  &:focus {
    background-color: $main-offset;
    color: $white;
  }
}

.ui.button.compact {
  padding-top: 9px;
  padding-bottom: 9px;
  height: 32px;
}

.split-view-button {
  .ui.button {
    border: solid 2px $main-colour !important;
    height: 32px;
    width: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      border-right: none !important;
    }
    &:last-child {
      border-left: none !important;
    }
    &:not(.disabled) {
      background-color: white;
    }
    &.disabled {
      background-color: $main-colour !important;
      opacity: 1 !important;
    }
  }
}

.filter-header-button.ui.button {
  margin-right: 1rem;
  border: solid 2px $main-colour;
  height: 32px;
  width: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
