@use "./variables.scss" as *;
@use "~@redriver/cinnamon/src/styles/cinnamon.scss" as *
  with(
    $import-google-fonts: true,
    $font-url: "https://fonts.googleapis.com/css?family=Open+Sans",
    $font-name: "Open Sans",
    $font-family: (
      $font-name,
      Arial,
      Helvetica,
      sans-serif,
    ),
    $font-size: 14px,
    $text-color: #393939,
    $link-color: #a0cd4e,
    $button-background-color: $main-colour,
    $button-text-color: $white,
    $toggle-background-color: $main-colour,
    $positive-color: $dark-green,
    $negative-color: $red
  );
@use "~simplebar-core/dist/simplebar" as *;
@use "./functions.scss" as *;
@use "./mixins.scss" as *;
@use "./global.scss" as *;
@use "./navigation.scss" as *;
@use "./containers.scss" as *;
@use "./table.scss" as *;
@use "./forms.scss" as *;
@use "./buttons.scss" as *;
@use "./modals.scss" as *;
@use "./swimlanes.scss" as *;
@use "./addresses.scss" as *;
@use "./comments.scss" as *;
@use "./controls.scss" as *;
@use "./sidepanels.scss" as *;
@use "./pages.scss" as *;
@use "./dashboard.scss" as *;

// page specific styles
@use "./customers.scss" as *;
@use "./enquiries.scss" as *;
@use "./invoices.scss" as *;
@use "./jobs.scss" as *;
@use "./login.scss" as *;
@use "./scheduling.scss" as *;
@use "./quotes.scss" as *;
@use "./users.scss" as *;
@use "./settings.scss" as *;
@use "./departments.scss" as *;
@use "./contracts.scss" as *;
@use "./display.scss" as *;
