@use "./variables.scss" as *;

// basic mixin for setting fonts
@mixin DefineFont(
  $size: $font-size,
  $weight: $regular-weight,
  $font: $font-name
) {
  font-family: $font;
  font-size: $size;
  font-weight: $weight;
}

@mixin Avatar($size) {
  width: $size;
  height: $size;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background-color;

  .initials {
    @include DefineFont($size * 0.4, $bold-weight);
    line-height: $size * 0.4;
    color: $black;
  }
}

// helper mixins for pre-defined font styles
// open sans
@mixin OpenSans23BoldText {
  @include DefineFont(23px, $bold-weight);
}
@mixin OpenSans20BoldText {
  @include DefineFont(20px, $bold-weight);
}
@mixin OpenSans18BoldText {
  @include DefineFont(18px, $bold-weight);
}
@mixin OpenSans18RegularText {
  @include DefineFont(18px, $regular-weight);
}
@mixin OpenSans16BoldText {
  @include DefineFont(16px, $bold-weight);
}
@mixin OpenSans14BoldText {
  @include DefineFont(14px, $bold-weight);
}
@mixin OpenSans15BoldText {
  @include DefineFont(15px, $bold-weight);
}
@mixin OpenSans16SemiBoldText {
  @include DefineFont(16px, $semibold-weight);
}
@mixin OpenSans16RegularText {
  @include DefineFont(16px, $regular-weight);
}
@mixin OpenSans14RegularText {
  @include DefineFont(14px, $regular-weight);
}
@mixin OpenSans13RegularText {
  @include DefineFont(13px, $regular-weight);
}
@mixin OpenSans12RegularText {
  @include DefineFont(12px, $regular-weight);
}
@mixin OpenSans10RegularText {
  @include DefineFont(10px, $regular-weight);
}

// lato
@mixin Lato13RegularText {
  @include DefineFont(13px, $regular-weight, $Lato);
}
@mixin Lato14RegularText {
  @include DefineFont(14px, $regular-weight, $Lato);
}
@mixin Lato16RegularText {
  @include DefineFont(16px, $regular-weight, $Lato);
}
@mixin Lato16BoldText {
  @include DefineFont(16px, $bold-weight, $Lato);
}
@mixin Lato20BoldText {
  @include DefineFont(20px, $bold-weight, $Lato);
}

// calibri
@mixin Calibri18RegularText {
  @include DefineFont(18px, $regular-weight, $Calibri);
}
@mixin Calibri14RegularText {
  @include DefineFont(14px, $regular-weight, $Calibri);
}
@mixin Calibri14BoldText {
  @include DefineFont(14px, $bold-weight, $Calibri);
}
@mixin Calibri18BoldText {
  @include DefineFont(18px, $bold-weight, $Calibri);
}
@mixin Calibri20BoldText {
  @include DefineFont(20px, $bold-weight, $Calibri);
}

@mixin BuildTileFillMediaQuery($min-width, $max-width, $num) {
  @media screen and (min-width: $min-width) and (max-width: $max-width) {
    &:not(.custom-breakpoints).wide {
      > .cell.medium {
        &:nth-child(#{$num}n) {
          padding-right: 0;
        }
        &:nth-child(1),
        &:nth-child(#{$num}n + 1) {
          padding-left: 0;
        }
      }
    }
  }
}

@mixin BuildTileBreakpointsFillMediaQuery($min-width, $max-width, $num) {
  @media screen and (min-width: $min-width) and (max-width: $max-width) {
    > .cell.medium {
      &:nth-child(#{$num}n) {
        padding-right: 0;
      }
      &:nth-child(1),
      &:nth-child(#{$num}n + 1) {
        padding-left: 0;
      }
    }
  }
}
