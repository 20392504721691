@use "./variables.scss" as *;
@use "./mixins.scss" as *;

.schedule-page {
  .page-content-wrapper {
    height: calc(100vh - 112px);
    padding: 1rem;
  }
}

.job-scheduling,
.resource-scheduling {
  display: grid;
  grid-template-columns: 0.25fr 0.75fr;
  grid-template-rows: calc(190px - 8px) calc(100px - 8px) calc(
      100% - 190px - 100px - 8px
    );

  flex-grow: 1;
  gap: 16px;
  max-height: 100%;
  min-height: 100%;
  overflow: hidden;

  & > * {
    margin: 0 !important;
  }

  .cin.number-formatter {
    word-break: break-all;
  }

  .jobs-to-be-scheduled {
    grid-column: 1;
    grid-row: 3;

    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    overflow: auto;

    > header {
      display: flex;
      justify-content: space-between;

      .ui.input {
        height: 32px;
      }
      margin-bottom: 0.5rem;
    }

    .unscheduled-jobs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
      justify-items: center;
      overflow-y: auto;

      .job-card {
        width: 100%;
        max-width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    .unschedule-column {
      height: 100%;
      width: 100%;
      align-content: initial;
      background-color: $hover-colour;
      border-radius: 4px;
      border: $border-grey 1px solid;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .department-filter,
  .department-selector {
    position: relative;
    grid-column: 1 / 3;
    grid-row: 2;

    .department-filter-header {
      display: flex;
      margin-bottom: 1rem;
      justify-content: space-between;

      > div {
        display: flex;
        > h4 {
          padding-right: 0.5rem;
        }
      }
    }

    .department-filter-body {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;

      &:not(.expanded) > div {
        max-height: 29px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }

      > div {
        border-radius: 4px;
        background-color: $picker-bg-color;
        padding: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &.selected {
          background-color: $picker-selected-bg-color;
          color: $white;
        }
        &.toggle {
          color: $picker-selected-bg-color;
          text-align: center;
          background-color: initial;
          font-weight: bold;
        }
      }

      &.expanded {
        position: absolute;
        width: 100%;
        background: $white;
        margin: 0 -14px -14px -14px;
        padding: 0 14px 14px 14px;
        box-shadow: 0px 3px 6px 0 $box-shadow-black;
        z-index: 100;

        .toggle {
          text-align: center;
          grid-column: 1 / 3;
        }
      }
    }
  }

  .overview-type-filter {
    display: flex;

    label {
      padding-right: 0.5rem;
      padding-top: 0.25em;
    }
    > div {
      background-color: $picker-bg-color;
      height: min-content;
      padding: 0.25em 0.5rem;
      cursor: pointer;

      &:first-of-type {
        border-radius: 4px 0 0 4px;
      }

      &:last-of-type {
        border-radius: 0 4px 4px 0;
      }

      &.active {
        background-color: $picker-selected-bg-color;
        color: $white;
      }
    }
  }

  .week-selector {
    grid-column: 1 / 3;
    grid-row: 1;
    display: flex;
    flex-direction: column;

    .week {
      border-radius: 4px;

      .month-label {
        font-size: 10px;
      }

      &.is-selected {
        border: $picker-selected-bg-color 1px solid;
      }

      &:hover {
        background-color: $faded-picker-bg-color;
      }
    }

    .week-header,
    > header {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 1em;
      align-items: center;

      h4 {
        margin-bottom: 0;
      }

      > div {
        margin-left: 2em;
      }

      .overview-type-filter {
        margin-left: auto;
      }
    }
    .daily-overview-tooltip {
      background-color: $blackish;
      border-radius: 4px;
      color: $white;
      padding: 0.5rem;
      z-index: 2000;

      h4 {
        margin-bottom: 0;
      }

      p {
        font-size: 12px;
        .excess {
          color: $red-text;
        }
      }
    }

    .week-slider {
      display: flex;
      justify-content: space-between;

      img {
        align-self: center;
        cursor: pointer;
        height: 30px;
      }
    }
  }

  .scheduled-jobs {
    grid-column: 2;
    grid-row: 3;

    display: grid;
    grid-gap: 0 16px;
    grid-template-rows: 50px minmax(auto, calc(100% - 50px));
    grid-template-columns: repeat(
      7,
      1fr
    ); // default column count - is overridden dynamically in component
    overflow: auto;

    .scheduled-day:first-child {
      margin-left: 0;
    }
    .scheduled-day:last-child,
    .last-day {
      margin-right: 0;
      &::after {
        display: none !important;
      }
    }
    .scheduled-day,
    .scheduled-day-header {
      position: relative;
      display: flex;
      flex-direction: column;
      // Adding a min-height prevents empty columns from collapsing
      min-height: 1px;

      h4 {
        margin-bottom: 0;
        font-weight: normal;
        &.today {
          font-weight: bold;
          position: relative;
        }
      }
      .today-text {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: start;
        > span {
          background-color: $picker-selected-bg-color;
          color: white;
          font-size: 10px;
          padding: 0 0.25rem;
          border-radius: 4px;
        }
      }
      .totals {
        font-size: 12px;
      }
      &::after {
        top: 0;
        right: -10px;
        position: absolute;
        content: "";
        display: block;
        width: 2px;
        height: 100%;
        background-color: $picker-bg-color;
      }
    }
    .scheduled-day-header {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        top: -1rem;
        left: 0;
        height: 1rem;
        width: 100%;
        background: white;
      }
    }

    .job-card {
      margin-bottom: 0.5rem;
    }
  }
  .job-card {
    background-size: 40px 40px;
    padding: 0.5rem;
    &.contract {
      padding-bottom: 0;
      footer {
        .contract-plots {
          font-size: smaller;
          padding-top: 0.25rem;
        }
      }
    }

    &:focus {
      outline: none;
    }

    header {
      margin-bottom: 0.5rem;
      h5 {
        margin-right: 1rem;
        margin-bottom: 0;
        margin-top: 0;
      }
      img {
        position: absolute;
        top: 5px;
        right: 5px;
      }
      .job-status {
        display: inline-block;
        font-weight: bold;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        &.invoiced {
          color: $red-text;
        }
        &.part-complete {
          color: $dark-green;
          text-transform: uppercase;
        }
      }
    }

    footer {
      display: flex;
      flex-direction: column;
      span {
        max-width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .job-card-container {
    &:focus {
      outline: none;
    }
  }

  &.resource-scheduling {
    grid-template-columns: 1fr;
    grid-template-rows: calc(60px - 8px) calc(100% - 60px - 8px);
    grid-gap: 8px;

    .filters {
      grid-column: 1;
      grid-row: 1;
      display: flex;
      .department-selector,
      .date-selector {
        width: calc(50% - 0.25em);
        margin: 0;
        padding: 0 0.5em;
      }
      .date-selector {
        margin-right: 0.25em;
      }
      .department-selector {
        margin-left: 0.25em;
      }
    }

    .resource-date {
      cursor: pointer;
      background-color: $picker-bg-color;
      height: 26px;
      padding: 0 1rem;
      border-radius: 4px;
      display: inline-flex;
      align-items: center;
      &.selected {
        background-color: $picker-selected-bg-color;
        color: $white;
        font-weight: bold;
      }
    }

    .resource-allocation {
      grid-column: 1;
      grid-row: 2;

      display: flex;
      flex-direction: column;
      max-height: 100%;
      overflow: hidden;

      .resource-allocation-header {
        display: flex;
        align-items: baseline;

        > .ui.input {
          margin-left: 1em;
        }
        > .ui.checkbox {
          margin-left: 1em;
        }
      }

      .resource-grid {
        display: grid;
        grid-gap: 9px;
        overflow: auto;
        // fallback grid definition - overridden by dynamic inline style in ResourceAllocation component
        grid-template-rows: auto repeat(1, 32px);
        grid-template-columns: 180px repeat(1, 105px);

        > .header {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: sticky;
          top: 0;
          background-color: white;
          z-index: 1;
          padding-bottom: 2px;
        }

        > .header-notes {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding-left: 70%;
          padding-bottom: 0.5em;
          background-color: white;
          position: sticky;
          top: 0;
          left: 0;
          z-index: 10;
        }
      }

      .notes {
        position: sticky;
        z-index: 5;
        left: 180px;
        background-color: white;
      }

      .resource {
        display: flex;
        padding: 0.25rem;
        align-items: center;
        justify-content: center;
        position: sticky;
        left: 0;
        background-color: white;
        z-index: 1;
        .department-colour-display {
          height: 100%;
          width: 0.5em;
          border-radius: 25px;
        }
        > .resource-name {
          min-width: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: auto;
          margin-right: auto;
        }

        > .absence-indicator {
          color: $red-text;
          font-weight: bold;
        }

        &:after {
          position: absolute;
          content: "";
          display: block;
          bottom: -5px;
          width: 100%;
          height: 1px;
          background-color: $picker-bg-color;
        }
      }

      .resource-job {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        padding: 0.5rem;
        > header {
          font-weight: bold;
          margin-top: 0.5rem;
          text-align: center;
          display: flex;
          justify-content: space-between;
          flex-flow: column wrap;
          .subtext {
            color: $subtext-blue;
            @include OpenSans13RegularText;
          }
        }
        .job-address {
          display: flex;
          justify-content: center;
          flex-direction: column;
          text-orientation: mixed;
          writing-mode: vertical-rl;
          transform: rotate(180deg);
        }
      }

      .allocation {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        .part-of-day {
          height: 100%;
          width: 50%;
          flex-grow: 0;
          flex-shrink: 0;
          background-color: $background-color;
          border: solid 1px $assigned-grey;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background-color: $cell-hover;
          }
          &.assigned-other {
            background-color: $light-grey;
          }
          &.not-assigned {
            border-color: $assigned-grey;
          }

          $leave-colours:
            "Absent" $darker-green $paler-green,
            "OtherEvent" $orange $orange-tint,
            "OnHoliday" $purple $purple-tint,
            "MaternityPaternity" $darker-blue $less-pale-blue,
            "Unavailable" $black $lighter-green;

          @each $name, $colour, $tint in $leave-colours {
            &.#{$name} {
              background-color: $tint;
              color: $colour;
            }
          }
        }

        .part-of-day:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        .has-absences {
          font-size: 0.8em;
          line-height: 1.2em;
          text-align: center;
        }

        .am {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        .pm {
          border-left: 0;
        }
      }
    }
  }
}

.slide-selector {
  display: flex;
  flex-direction: column;
  height: 100%;
  .slide-selector-body {
    flex-grow: 1;
    display: flex;
    align-items: center;
    img {
      align-self: center;
      cursor: pointer;
      height: 30px;
      width: 15px;
    }

    .items-area {
      display: inline-block;
      position: relative;
      height: 100%;
    }

    .item-wrapper {
      position: absolute;
      max-width: 100%;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
}

.job-popup.ui.popup {
  background-color: $blackish;
  color: white;
  min-width: 250px;
  max-width: 350px;
  max-height: 30vh;
  padding-right: 2px;

  &::before {
    background-color: $blackish;
  }

  .scope-of-work {
    overflow-y: auto;
    height: 100%;
    padding-right: 5px;
    &::-webkit-scrollbar-thumb {
      background: white;
    }
    & > header {
      display: flex;
      justify-content: space-between;
      flex-flow: column wrap;
      margin-bottom: 5px;
    }
    .title {
      padding-right: 0.5rem;
    }
    .job-item {
      display: flex;
      flex-direction: column;
      margin-top: 0.5rem;
      .details {
        display: flex;
        img {
          margin-left: 0.5rem;
        }
      }
      .description {
        font-size: 10px;
      }
    }
    .no-items {
      margin-top: 0.5rem;
    }
    footer {
      margin-top: 5px;
    }
  }
}

.spread-scope-of-work {
  margin-bottom: 1rem;
  header {
    margin-bottom: 1rem;
    span {
      margin-right: 6rem;
    }
  }

  .job-item {
    margin-bottom: 0.5rem;
  }
}

.job-context-menu.ui.popup {
  color: white;
  z-index: 999;

  padding: 0;
  background-color: transparent;
  border: none;

  .ui.menu {
    width: auto;
    border: none;
    .item {
      background-color: $blackish;
      color: white;
      border-radius: 0;
    }
    :first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    :last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .context-menu-item {
    line-height: initial;
    cursor: pointer;
  }
}

.day-selector {
  .day-options {
    display: inline-flex;
    > span {
      padding: 0.25rem 0.5rem;
      cursor: pointer;
      &.active {
        color: $main-colour;
        font-weight: $bold-weight;
      }
    }
  }
}

.department-selector {
  > header {
    display: flex;
    margin-bottom: 0.75rem;
    > * {
      margin-bottom: 0;
    }
  }
  .slide-selector-body .item-wrapper {
    overflow: hidden;
    justify-content: flex-start;
  }
  .department {
    cursor: pointer;
    background-color: $picker-bg-color;
    height: 26px;
    padding: 0 1rem;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    margin-right: 0.25rem;
    flex-shrink: 0;
    margin-left: 0.25rem;
    &.selected {
      background-color: $picker-selected-bg-color;
      color: $white;
      font-weight: bold;
    }
  }
}

.resource-scheduling .department-selector {
  .department {
    &.selected.default {
      background-color: $picker-bg-color;
      color: $black;
      outline: 1px solid $selected-grey;
    }
  }
}

.toggle-all {
  color: $picker-selected-bg-color;
  font-weight: bold;
  cursor: pointer;
}

.department-selector-header {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-left: 1rem;
}

.complete-job-sheet {
  td.item-complete div.ui.checkbox {
    label::after {
      content: "\e800";
      transform: none;
      opacity: 1;
      color: $light-grey;
    }
  }
}
